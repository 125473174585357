import React, { useContext, useEffect, useState } from 'react';
import PreviewCardHeader from '../componentUtils/previewCardHeader';
import { Card } from '@mui/material';
import './preview.css';
import { getLocationDisplay } from '../componentUtils/formatDisplayName';

const PreviewOccupation = ({ profile, isEditButton }) => {
  return (
    <Card>
      <div style={{ margin: '16px' }}>
        <PreviewCardHeader heading={'Occupation details'} previewStepValue={1} isEditButton={isEditButton} />

        <div className="info-wrapper">
          <div className="info-values">
            <div className="label">Job Type</div>
            <div className="value">
              {profile?.occupation[0]?.job_type == '' ? 'Not Specified' : profile?.occupation[0]?.job_type}
            </div>
          </div>

          <div className="info-values">
            <div className="label">Occupation</div>
            <div className="value">
              {profile?.occupation[0]?.post == '' ? 'Not Specified' : profile?.occupation[0]?.post}
            </div>
          </div>

          <div className="info-values">
            <div className="label">Currently working</div>
            <div className="value">
              {profile?.occupation[0]?.company_name == '' ? 'Not Specified' : profile?.occupation[0]?.company_name}
            </div>
          </div>

          <div className="info-values">
            <div className="label">Location</div>
            <div className="value">{getLocationDisplay(profile?.occupation[0]?.location)}</div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default PreviewOccupation;
